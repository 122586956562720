import { FC, ReactNode } from "react";
import { css, StyleSheet } from "aphrodite";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text from "@ui/text";
import { DeprecatedIconType } from "@src/deprecatedDesignSystem/deprecatedIcons";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { curves, durations, properties } from "../styles/transitions";
import { Button } from "@src/ui/button";

type Props = {
  leftIcon: DeprecatedIconType;
  placeholder: string;
  onClick: () => void;
  label?: string;
  children?: ReactNode;
  dataTestId?: string;
};

const ButtonWithPreview: FC<Props> = ({
  leftIcon,
  placeholder,
  onClick,
  children,
  label,
  dataTestId,
}) => {
  return (
    <AutoLayout
      alignSelf="stretch"
      direction="vertical"
      borderRadius={12}
      className={css(styles.buttonWithPreview)}
    >
      <AutoLayout
        alignSelf="stretch"
        alignmentVertical="center"
        spacingMode="space-between"
        height={44}
        onClick={onClick}
        paddingLeft={8}
        paddingRight={12}
        className={css(styles.button)}
        data-testid={dataTestId || "select-users-button"}
      >
        <AutoLayout alignmentVertical="center" spaceBetweenItems={4}>
          <DeprecatedIcon
            type={leftIcon}
            iconSize={20}
            color={deprecatedTones.gray6}
          />
          <Text
            type="P2"
            fontWeight="Medium"
            color={label ? deprecatedTones.black : deprecatedTones.gray5}
          >
            {label ? label : placeholder}
          </Text>
        </AutoLayout>
        <Button variant="default" className="h-[28px] px-[8px]">
          Edit
        </Button>
      </AutoLayout>
      {children && <AutoLayout alignSelf="stretch">{children}</AutoLayout>}
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  buttonWithPreview: {
    borderRadius: 12,
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    overflow: "hidden",
  },
  button: {
    backgroundColor: deprecatedTones.white,
    transitionDuration: durations.S025,
    transitionProperty: properties.backgroundColor,
    transitionTimingFunction: curves.bezier,
    cursor: "pointer",
    ":hover": {
      backgroundColor: deprecatedTones.gray3Alpha,
    },
  },
});

export default ButtonWithPreview;
