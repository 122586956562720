import { useToast } from "@src/hooks/useToast";
import { useTracking } from "@src/hooks/useTracking";
import { Button } from "@src/ui/button";
import EmailOutlineIcon from "@src/ui/icons/18px/email-outline";
import { launchConfetti } from "@src/utils/confetti";
import { FC, useCallback } from "react";

type Props = {
  className?: string;
};
const UpgradeButton: FC<Props> = (props) => {
  const { trackEvent } = useTracking();
  const { addToast } = useToast();
  const onClick = useCallback(() => {
    launchConfetti();
    trackEvent("clicked_upgrade_button", {});
    addToast({
      message: "Nice! Someone from our team will reach out to you shortly!",
    });
  }, [trackEvent, addToast]);
  return (
    <Button onClick={onClick} className={props.className}>
      <EmailOutlineIcon />
      Contact sales
    </Button>
  );
};

export default UpgradeButton;
